<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="300"
    viewBox="0 0 20 12"
  >
    <rect width="20" height="12" fill="#006a4e" />
    <circle cx="9" cy="6" r="4" fill="#f42a41" />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>
